.content-wrapper {
  overflow-x: auto;
  text-align: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 0 50px 0;
}

a {
  text-decoration: none;
}

h2 {
  color: #af8447;
  font-size: 25px;
  font-weight: 500;
  line-height: 1em;
  margin-top: 0;
  margin-bottom: 10px;
  letter-spacing: 2px;
}
